import React, { useState, useEffect } from "react";
import PORTNUMBER from "../../../constant/api";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import axios from "axios";
import "../../../table.css";

const BidIncrementsList = (props) => {
  const PORT = PORTNUMBER.PORTNUMBER;
  const [bidIncrementRecords, setBidIncrementRecords] = useState(
    [{
      from: "",
      to: "",
      bid_increment: ""
    }]
  );

  useEffect(async () => {
    getAllBidIncrementsLists();
  }, []);

  const getAllBidIncrementsLists = async () => {
    await axios
      .get(`${PORT}/bidIncrementList`)
      .then((response, err) => {
        console.log("response", response);
        if (response.data.status === 200) {
          setBidIncrementRecords(response.data.data);
        }
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await axios
      .post(`${PORT}/editBidIncrements`, {
        bidIncrementRecords,
      })
      .then((res, err) => {
        toast.success("Bid Increments Updated.");
        getAllBidIncrementsLists();
      });
  };

  const handleFormChange = (index, event, name) => {
    let data = [...bidIncrementRecords];
    data[index][event.target.name] = event.target.value;
    setBidIncrementRecords(data);
  }

  const addPriceRangeBlock = (i, e) => {
    let newfield = {
      from: "",
      to: "",
      bid_increment: ""
    };
    setBidIncrementRecords([...bidIncrementRecords, newfield]);
  };

  const removePriceRangeBlock = (i, e) => {
    let data = [...bidIncrementRecords];
    data.splice(i, 1);
    setBidIncrementRecords(data);
  };

  return (
    <div style={{ width: "100%" }} class="work_space">
      <div class="workspace_title_hd">
        <div class="row">
          <div class="col-md-12">
            <div class="titleof_page">
              <ul class="breadcrumb_list topsmall_bottom">
                <li>
                  <a href="javascript:void(0)">Admin Panel </a>
                </li>
                <li>
                  <i style={{ padding: "5px" }} class="fa fa-angle-right"></i>
                </li>
                <li>Bid Increment</li>
              </ul>
            </div>
          </div>
          <div class="col-md-12">
            <div class="main_oflistingForm bg_light_gray onlyforcol_support">
              <div style={{ overflowX: "auto" }} class="tabscroll_perent">
                <div class="col-md-12">
                  <form onSubmit={handleSubmit}>
                    <div class="row">
                      <div class="col-md-9">
                        <h4 className="fieldsec_lab">Bid Increment<p className="text-danger" style={{ display: "inline-block" }}>*</p></h4>
                        <br></br>
                        {bidIncrementRecords && bidIncrementRecords.length > 0 && bidIncrementRecords.map((data, index) => {
                          return (<div key={index}>
                            <div className="row repwat_one">
                              <div className="col-2">
                                <p>Price Range</p>
                              </div>
                              <div className="row col-10 mb-2">
                                <div className="col-4 mb-2">
                                  <input className="fuilddesh" name="from" type="number" min="0" step="any" placeholder="From" onChange={event => handleFormChange(index, event, 'range')} value={data?.from} />
                                </div>
                                <div className="col-4 mb-2">
                                  <input className="fuilddesh" name="to" type="number" min="0" step="any" placeholder="To" onChange={event => handleFormChange(index, event, 'range')} value={data?.to} />
                                </div>
                                {index === 0 ?
                                  <div className="col-3 mb-2">
                                    <a className="cursor-pointer" href={void (0)} onClick={event => addPriceRangeBlock(index, event)} >Add more</a>
                                  </div> : <div className="col-3 mb-2">
                                    <a className="cursor-pointer" href={void (0)} onClick={event => removePriceRangeBlock(index, event)} >Remove</a>
                                  </div>}
                              </div>
                            </div>
                            <div className="row repwat_one">
                              <div className="col-2">
                                <p>Bid Increment</p>
                              </div>
                              <div className="row col-10 mb-2">
                                <div className="col-8 mb-2">
                                  <input className="fuilddesh" name="bid_increment" type="number" min="0" step="any" placeholder="Bid Increment" onChange={event => handleFormChange(index, event, 'range')} value={data?.bid_increment} />
                                </div>
                              </div>
                            </div>
                            <hr />
                          </div>);
                        })}
                      </div>
                      <div class="col-md-3">
                        <table>
                          <tr>
                            <th>Current Price</th>
                            <th>Bid Increment</th>
                          </tr>
                          {bidIncrementRecords && bidIncrementRecords.length > 0 && bidIncrementRecords.map((data, index) => {
                            return (
                              <tr>
                                <td>£{data.from} - £{data.to}</td>
                                <td>£{data.bid_increment}</td>
                              </tr>
                            )
                          })
                          }
                        </table>
                      </div>
                    </div>
                    <div className="bottomsend_button text-center small_margin_top">
                      <button type="submit" className="savesidebutt closefix">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default BidIncrementsList;
