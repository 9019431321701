import React, { useState, useEffect } from 'react';
import "./signup.css";
import AllImages from "../../constant/img";
import axios from "axios";
import loginAPI from "../../constant/api";
import { Link, useHistory, useLocation } from "react-router-dom";
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import logo002 from "../../constant/img";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";

const Signup = (props) => {
    // console.log(props)
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const history = useHistory();
    const location = useLocation();

    const pathname = location.pathname;
    console.log("signuppathname", pathname);


    useEffect(() => {
        console.log("loginpageprops", props);
        if (typeof props.location.customdata != "undefined" && props.location.customdata) {
            toast.success("You have successfully logout");
        }
        else {
            return;
        }
    }, [])


    useEffect(() => {
        const Auth1 = localStorage.getItem("username");
        const Auth2 = localStorage.getItem("password");

        document.getElementById("main_app1").classList.remove("main_board");
        document.getElementById("main_app2").classList.remove("main_board_inner");

        if (Auth1 !== null && Auth2 !== null) {
            history.push("/admin");
        }
    })



    const handleChangeEmail = (event) => {
        event.preventDefault();
        console.log(event.target.value);
        setEmail(event.target.value);
    }
    const handleChangePassword = (event) => {
        event.preventDefault();
        console.log(event.target.value);
        setPassword(event.target.value);
    }



    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("email:", email);
        console.log("password:", password);
        axios.post(loginAPI.LOGIN_API, { params: { email, password } }).then((res, err) => {
            if (res.data == "Please enter correct credentials!") {
                console.log("errorhaiye", err);
                document.getElementById("incorrect").style.display = "block";
                setEmail("");
                setPassword("");
            } else {
                console.log("responsehaiye", res);
                localStorage.setItem("username", email);
                localStorage.setItem("password", password);
                // history.push("/admin");
                history.push({ pathname: "/admin", customdata: { pathname } })
            }
        })
    }



    //  const responseFacebook = (response) => {
    //   console.log("facebook", response);
    // }

    // const responseGoogle = (response) => {
    //   console.log("GOOGLE", response);
    // }




    return (
        <>
            <header class="scndHeader">

                <div class="loginHeader">
                    <Link to="/">
                        <img src = "https://dlevelns.stage02.obdemo.com/image/logo/headerImage_1711958521736.png"/>
                        </Link>
                </div>

            </header>

            <section class="signin_Newpage bg-light-dark repeat-section-login-New" >
                <div class="container" >
                    <div class="row" >
                        <div class="col-12" >
                            <div class="loginbx_main_New" >
                                <div class="log_sign_inner_New" >
                                    <div class="loginheadName">Login</div>
                                    <ul class="nav nav-tabs logintab_New"
                                        role="tablist" >
                                        <li style={
                                            { display: "none" }}
                                            id="incorrect"
                                            class="nav-item New-W-50 text-center" > <p class="text-danger"> Please enter correct credentials </p></li>
                                    </ul> <div class="tab-content" >
                                        <div class="tab-pane active"
                                            id="tabs-1"
                                            role="tabpanel" >
                                            {
                                                /* <div className="App">
                                                               <h7>LOGIN WITH FACEBOOK AND GOOGLE</h7>
                                    
                                                               <FacebookLogin
                                                                appId="225368672664570"
                                                                fields="name,email,picture"
                                                                callback={responseFacebook}
                                                               />
                                                              <br />
                                                              <br />
                                    
                                    
                                                              <GoogleLogin
                                                               clientId="343470913252-nnu1mgq1ja6sksdj94a1oddilgv6fhs4.apps.googleusercontent.com"
                                                               buttonText="LOGIN WITH GOOGLE"
                                                               onSuccess={responseGoogle}
                                                               onFailure={responseGoogle}
                                                              />
                                    
                                                             </div> */
                                            }
                                            <form action="get" onSubmit={handleSubmit} >
                                                <div class="formgrop_main_New row">
                                                    <div class="col-sm-12 margin_top_new">
                                                        <label> Email </label>
                                                        <div class="form-group" >
                                                            <input onChange={handleChangeEmail} type="text" value={email} class="form-control New_control" placeholder="Email Address"
                                                                required />
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 mt-2" >
                                                        <label > Password </label> <div class="form-group icon_input signpass_New" >
                                                            <input onChange={handleChangePassword} type="password" value={password} class="form-control New_control" placeholder="Password"
                                                                required /> { /* <a href="#" class="eyeshow_New"><img src={AllImages.EYE_IMAGE} alt="Icon" width="20" /></a>  */} </div> </div>
                                                    <div class="col-sm-6" >
                                                        <p class="text_New" > <a href="/forgetPassword"> Forgot Password ? </a></p>
                                                    </div>
                                                    {
                                                        /* <div class="col-sm-6">
                                                                            <div class="form-group">
                                                                                <div class="custom_cheak_New">
                                                                                <ul>
                                                                                    <li>
                                                                                    <div class="form-check">
                                                                                        <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                                                                                        <label class="form-check-label f-12" for="exampleCheck1">Keep me logged in</label>
                                                                                    </div>
                                                                                    </li>
                                                                                </ul>
                                                                                </div>
                                                                            </div>
                                                                            </div> */
                                                    }
                                                    <div class="col-sm-12 marginfour_side" >
                                                        <div class="form-group text-center" >
                                                            <button type="submit" class="btnx btn-secondaryx"> Login </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>

            </section>

            <footer class="footerside margin_top text-center"><h5 class="footercont">Copyright © 2025 dboroll Inc. All Rights Reserved.</h5></footer>
        </>
    )
}

export default Signup