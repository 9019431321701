import React from 'react';
import {useLocation} from "react-router-dom";

const Footer = () => {

    const location = useLocation();
   // console.log("pathnamehaiye", location.pathname);
    const pathname = location.pathname;

    const allPathEdit = pathname.split("/");
   // console.log("allpathinheader", allPathEdit);

    if(location.pathname === "/" || location.pathname === "/forgetPassword" || location.pathname === "/confirmPassword" || allPathEdit[2] === "confirmPassword")
    {
       return null 
    }
    else{
        return (
            <footer className="footerside margin_top">
             <h5 className="footercont">Copyright © 2025 dboroll Inc. All Rights Reserved.</h5>
            </footer>
        )
    }
}

export default Footer
