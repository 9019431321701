import React, { useState, useEffect } from "react";
import axios from "axios";
import PORTNUMBER from "../../../constant/api";
import { toast, ToastContainer } from "react-toastify";

const Listings = () => {
  const PORT = PORTNUMBER.PORTNUMBER;
  const [autoApprocalOfListings, setAutoApprocalOfListings] = useState();
  const [autoApprocalOfEditedListings, setAutoApprocalOfEditedListings] =
    useState();
  const [numberOfListingsPerPage, setNumberOfListingsPerPage] = useState();
  const [productExpiryDays, setProductExpiryDays] = useState();
  const [numberOfListingsOnRowGrid, setNumberOfListingsOnRowGrid] = useState();
  const [numberOfListingsOnRowList, setNumberOfListingsOnRowList] = useState();
  const [sortAds, setSortAds] = useState();
  const [defaultListingGridView, setDefaultListingGridView] = useState();

  const [numberOfListingsInBox, setNumberOfListingsInBox] = useState();

  const [listingVisitCounter, setListingVisitCounter] = useState();
  const [pictureViewCounter, setPictureViewCounter] = useState();
  const [postingDateInListings, setPostingDateInListings] = useState();
  const [slug, setSlug] = useState();

  useEffect(async () => {
    GETALL();
  }, []);

  const GETALL = async () => {
    await axios.get(`${PORT}/listings/all`).then((res, err) => {
      if (res) {
        console.log(res.data.data);

        if (res.data.data != "") {
          setSlug(res.data.data[0].slug);
          setAutoApprocalOfListings(
            res.data.data[0].common_setting[0].autoApprocalOfListings
          );
          setAutoApprocalOfEditedListings(
            res.data.data[0].common_setting[0].autoApprocalOfEditedListings
          );
          setNumberOfListingsPerPage(
            res.data.data[0].common_setting[0].numberOfListingsPerPage
          );
          setProductExpiryDays(
            res.data.data[0].common_setting[0].productExpiryDays
          );
          setNumberOfListingsOnRowGrid(
            res.data.data[0].common_setting[0].numberOfListingsOnRowGrid
          );
          setNumberOfListingsOnRowList(
            res.data.data[0].common_setting[0].numberOfListingsOnRowList
          );
          setSortAds(res.data.data[0].common_setting[0].sortAds);
          setDefaultListingGridView(
            res.data.data[0].common_setting[0].defaultListingGridView
          );
          setNumberOfListingsInBox(
            res.data.data[0].similar_products[0].numberOfListingsInBox
          );
          setListingVisitCounter(
            res.data.data[0].listing_counts_settings[0].listingVisitCounter
          );
          setPictureViewCounter(
            res.data.data[0].listing_counts_settings[0].pictureViewCounter
          );
          setPostingDateInListings(
            res.data.data[0].listing_counts_settings[0].postingDateInListings
          );
        }
      }
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (autoApprocalOfListings == 1) {
      var autoApprocalOfListings_check = true;
    } else {
      var autoApprocalOfListings_check = false;
    }
    if (autoApprocalOfEditedListings == 1) {
      var autoApprocalOfEditedListings_check = true;
    } else {
      var autoApprocalOfEditedListings_check = false;
    }
    if (listingVisitCounter == 1) {
      var listingVisitCounter_check = true;
    } else {
      var listingVisitCounter_check = false;
    }
    if (pictureViewCounter == 1) {
      var pictureViewCounter_check = true;
    } else {
      var pictureViewCounter_check = false;
    }
    if (postingDateInListings == 1) {
      var postingDateInListings_check = true;
    } else {
      var postingDateInListings_check = false;
    }

    var common_settings = [
      {
        autoApprocalOfListings: autoApprocalOfListings_check,
        autoApprocalOfEditedListings: autoApprocalOfEditedListings_check,
        numberOfListingsPerPage: numberOfListingsPerPage,
        numberOfListingsOnRowGrid: numberOfListingsOnRowGrid,
        productExpiryDays: productExpiryDays,
        numberOfListingsOnRowList: numberOfListingsOnRowList,
        sortAds: sortAds,
        defaultListingGridView: defaultListingGridView,
      },
    ];
    var similar_products = [
      {
        numberOfListingsInBox: numberOfListingsInBox,
      },
    ];
    var listing_counts_settings = [
      {
        listingVisitCounter: listingVisitCounter_check,
        pictureViewCounter: pictureViewCounter_check,
        postingDateInListings: postingDateInListings_check,
      },
    ];

    if (slug == "ListingsSetting") {
      await axios
        .post(`${PORT}/listings/update`, {
          common_settings: JSON.stringify(common_settings),
          similar_products: JSON.stringify(similar_products),
          listing_counts_settings: JSON.stringify(listing_counts_settings),
        })
        .then((res, err) => {
          if (res) {
            toast.success("You have successfully changed the settings");
          }
        });
    } else {
      await axios
        .post(`${PORT}/listings/create`, {
          common_settings: JSON.stringify(common_settings),
          similar_products: JSON.stringify(similar_products),
          listing_counts_settings: JSON.stringify(listing_counts_settings),
        })
        .then((res, err) => {
          if (res) {
            toast.success("You have successfully changed the settings");
          }
        });
    }
    GETALL();
  };

  const handleAutoApprocalOfListings = (event) => {
    setAutoApprocalOfListings(event.target.value);
  };
  const handleAutoApprocalOfEditedListings = (event) => {
    setAutoApprocalOfEditedListings(event.target.value);
  };
  const handleNumberOfListingsPerPage = (event) => {
    setNumberOfListingsPerPage(event.target.value);
  };
  const handleProductExpiryDays = (event) => {
    setProductExpiryDays(event.target.value);
  };
  const handleNumberOfListingsOnRowGrid = (event) => {
    setNumberOfListingsOnRowGrid(event.target.value);
  };
  const handleNumberOfListingsOnRowList = (event) => {
    setNumberOfListingsOnRowList(event.target.value);
  };
  const handleSortAds = (event) => {
    setSortAds(event.target.value);
  };
  const handleDefaultListingGridView = (event) => {
    setDefaultListingGridView(event.target.value);
  };
  const handleNumberOfListingsInBox = (event) => {
    setNumberOfListingsInBox(event.target.value);
  };
  const handleListingVisitCounter = (event) => {
    setListingVisitCounter(event.target.value);
  };
  const handlePictureViewCounter = (event) => {
    setPictureViewCounter(event.target.value);
  };
  const handlePostingDateInListings = (event) => {
    setPostingDateInListings(event.target.value);
  };
  return (
    <div class="tab-pane" id="basic05" role="tabpanel">
      <form onSubmit={handleSubmit}>
        <h3 class="tabcont_lable">
          Basic settings for <span>Listings</span>
        </h3>
        <div class="labelsidetop">
          <div class="boxrepetform">
            <h4 class="tabcont_Sublable">Common Settings</h4>
            <div class="Tbaleflex">
              <div class="Table">
                <div class="Table-row">
                  <div class="Table-row-item">
                    <div class="lefttabl_lable">
                      <span>Auto approval of listings</span>
                    </div>
                  </div>
                  <div class="Table-row-item">
                    <div class="dropdownsid">
                      <div class="form_group">
                        <ul class="list">
                          <li class="list__item">
                            <input
                              type="radio"
                              id="autoApprocalOfListings1"
                              name="autoApprocalOfListings"
                              value="1"
                              onClick={handleAutoApprocalOfListings}
                              checked={
                                autoApprocalOfListings == 1 ? "checked" : ""
                              }
                            />
                            <label for="autoApprocalOfListings1" class="label">
                              Enable
                            </label>
                          </li>
                          <li class="list__item">
                            <input
                              type="radio"
                              id="autoApprocalOfListings2"
                              name="autoApprocalOfListings"
                              value="0"
                              onClick={handleAutoApprocalOfListings}
                              checked={
                                autoApprocalOfListings == 0 ? "checked" : ""
                              }
                            />
                            <label for="autoApprocalOfListings2" class="label">
                              Disable
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="Table-row">
                  <div class="Table-row-item">
                    <div class="lefttabl_lable">
                      <span>Auto approval of edited listings</span>
                    </div>
                  </div>
                  <div class="Table-row-item">
                    <div class="dropdownsid">
                      <div class="form_group">
                        <ul class="list">
                          <li class="list__item">
                            <input
                              type="radio"
                              id="autoApprocalOfEditedListings1"
                              name="autoApprocalOfEditedListings"
                              value="1"
                              onClick={handleAutoApprocalOfEditedListings}
                              checked={
                                autoApprocalOfEditedListings == 1
                                  ? "checked"
                                  : ""
                              }
                            />
                            <label
                              for="autoApprocalOfEditedListings1"
                              class="label"
                            >
                              Enable
                            </label>
                          </li>
                          <li class="list__item">
                            <input
                              type="radio"
                              id="autoApprocalOfEditedListings2"
                              name="autoApprocalOfEditedListings"
                              value="0"
                              onClick={handleAutoApprocalOfEditedListings}
                              checked={
                                autoApprocalOfEditedListings == 0
                                  ? "checked"
                                  : ""
                              }
                            />
                            <label
                              for="autoApprocalOfEditedListings2"
                              class="label"
                            >
                              Disable
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="Table-row">
                  <div class="Table-row-item">
                    <div class="lefttabl_lable">
                      <span>Number of listing per page</span>
                    </div>
                  </div>
                  <div class="Table-row-item">
                    <div class="dropdownsid">
                      <input
                        type="number"
                        class="fuilddesh"
                        onChange={handleNumberOfListingsPerPage}
                        value={numberOfListingsPerPage}
                        name="numberOfListingsPerPage"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div class="Table-row">
                  <div class="Table-row-item">
                    <div class="lefttabl_lable">
                      <span>Number of listings on row (grid)</span>
                    </div>
                  </div>
                  <div class="Table-row-item">
                    <div class="dropdownsid">
                      <input
                        type="number"
                        class="fuilddesh"
                        onChange={handleNumberOfListingsOnRowGrid}
                        value={numberOfListingsOnRowGrid}
                        name="numberOfListingsOnRowGrid"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div class="Table-row">
                  <div class="Table-row-item">
                    <div class="lefttabl_lable">
                      <span>Number of listings on row (list)</span>
                    </div>
                  </div>
                  <div class="Table-row-item">
                    <div class="dropdownsid">
                      <input
                        type="number"
                        class="fuilddesh"
                        onChange={handleNumberOfListingsOnRowList}
                        value={numberOfListingsOnRowList}
                        name="numberOfListingsOnRowList"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                {/* <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Banner space after every</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <select class="fuilddesh">
                                    <option value="">1</option>
                                    <option value="">2</option>
                                    <option value="">3</option>
                                    <option value="">4</option>
                                    <option value="">5</option>
                                    <option value="">6</option>
                                    <option value="">7</option>
                                    <option value="">8</option>
                                    <option value="">9</option>
                                </select>
                            </div>
                            <span class="equalspan_cont">Liting's but not last</span>
                            </div>
                        </div>
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Number of listings o RSS page</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <input type="text" class="fuilddesh" name="" placeholder="" />
                            </div>
                            </div>
                        </div>
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Keep URLs of expired and removed listing alive for SEO puposes</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid radioequal_flex">
                                <div class="form_group">
                                    <ul class="list">                            
                                        <li class="list__item">
                                        <input type="radio" id="test93" name="radio-group59" checked />
                                        <label for="test93" class="label">Enable</label>
                                        </li>                            
                                        <li class="list__item">
                                        <input type="radio" id="test94" name="radio-group59" />
                                        <label for="test94" class="label">Disable</label>
                                        </li>
                                    </ul>
                                </div>
                                <span class="equalspan_cont">The options is unavailable with the trash box disabled</span>
                            </div>
                            </div>
                        </div>
                        <div class="Table-row">
                        <div class="Table-row-item">
                            <div class="lefttabl_lable">
                            <span>Add fields to be hidden in details of removed or expired listings</span>
                            </div>
                        </div>
                        <div class="Table-row-item">
                            <div class="dropdownsid onlyfor_full_width">
                            <textarea type="text" class="fuilddesh fuilddesh_textarea minwidth02" placeholder="" ></textarea>
                            </div>
                        </div>
                        </div> */}
                {/* <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Update posting date of listings after upgrading</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid radioequal_flex">
                                <div class="form_group">
                                    <ul class="list">                            
                                        <li class="list__item">
                                        <input type="radio" id="test95" name="radio-group60" checked />
                                        <label for="test95" class="label">Enable</label>
                                        </li>                            
                                        <li class="list__item">
                                        <input type="radio" id="test96" name="radio-group60" />
                                        <label for="test96" class="label">Disable</label>
                                        </li>
                                    </ul>
                                </div>
                                <span class="equalspan_cont">The options is unavailable with the trash box disabled</span>
                            </div>
                            </div>
                        </div> */}
                <div class="Table-row">
                  <div class="Table-row-item">
                    <div class="lefttabl_lable">
                      <span>Sort ads I recently added page by</span>
                    </div>
                  </div>
                  <div class="Table-row-item">
                    <div class="dropdownsid">
                      <select
                        class="fuilddesh"
                        onChange={handleSortAds}
                        value={sortAds}
                      >
                        <option value="">Select</option>
                        <option value="date">Date</option>
                      </select>
                    </div>
                  </div>
                </div>
                {/* <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Primary price filed products</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <select class="fuilddesh">
                                    <option value="">Price</option>
                                </select>
                            </div>
                            </div>
                        </div> */}
                <div class="Table-row">
                  <div class="Table-row-item">
                    <div class="lefttabl_lable">
                      <span>Default listing grid view</span>
                    </div>
                  </div>
                  <div class="Table-row-item">
                    <div class="dropdownsid">
                      <select
                        class="fuilddesh"
                        onChange={handleDefaultListingGridView}
                        value={defaultListingGridView}
                      >
                        <option value="">Select</option>
                        <option value="List">List</option>
                      </select>
                    </div>
                    <span class="equalspan_cont">
                      Sets default grid view for listing on pages unless
                      overridden bu user section.
                    </span>
                  </div>
                </div>
                <div class="Table-row">
                  <div class="Table-row-item">
                    <div class="lefttabl_lable">
                      <span>Product Expiry Duration</span>
                    </div>
                  </div>
                  <div class="Table-row-item">
                    <div class="dropdownsid">
                      <input
                        type="number"
                        class="fuilddesh"
                        onChange={handleProductExpiryDays}
                        value={productExpiryDays}
                        name="productExpiryDays"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                {/* <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Primary price filed jobs</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <select class="fuilddesh">
                                    <option value="">Price</option>
                                </select>
                            </div>
                            </div>
                        </div>
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Default listing grid view</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <select class="fuilddesh">
                                    <option value="">list</option>
                                </select>
                            </div>
                            <span class="equalspan_cont">Sets default grid view for listing on pages unless overridden bu user section.</span>
                            </div>
                        </div> */}
              </div>
            </div>
          </div>

          {/* <div class="boxrepetform">
                    <h4 class="tabcont_Sublable">Featured setting</h4>
                    <div class="Tbaleflex">
                    <div class="Table">
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Numbers of flash sale  listing in a row</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <input type="text" class="fuilddesh minwidth02" placeholder="" />
                            </div>
                            </div>
                        </div>
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span> Number of latest products in a row</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <input type="text" class="fuilddesh minwidth02" placeholder="" />
                            </div>
                            </div>
                        </div>
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span> Slideshow delay for featured gallery on home page</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <input type="text" class="fuilddesh minwidth02" placeholder="" />
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div> */}

          <div class="boxrepetform">
            <h4 class="tabcont_Sublable">Similar products</h4>
            <div class="Tbaleflex">
              <div class="Table">
                <div class="Table-row">
                  <div class="Table-row-item">
                    <div class="lefttabl_lable">
                      <span>Number of listing in a box</span>
                    </div>
                  </div>
                  <div class="Table-row-item">
                    <div class="dropdownsid">
                      <input
                        type="number"
                        class="fuilddesh minwidth02"
                        onChange={handleNumberOfListingsInBox}
                        value={numberOfListingsInBox}
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                {/* <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Medium relevance</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <div class="form_group">
                                <ul class="list">                            
                                    <li class="list__item">
                                        <input type="radio" id="test97" name="radio-group61" />
                                        <label for="test97" class="label">Enable</label>
                                    </li>                            
                                    <li class="list__item">
                                        <input type="radio" id="test98" name="radio-group61" checked="" />
                                        <label for="test98" class="label">Disable</label>
                                    </li>
                                </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Category relevance</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <div class="form_group">
                                <ul class="list">                            
                                    <li class="list__item">
                                        <input type="radio" id="test99" name="radio-group62" />
                                        <label for="test99" class="label">Enable</label>
                                    </li>                            
                                    <li class="list__item">
                                        <input type="radio" id="test100" name="radio-group62" checked="" />
                                        <label for="test100" class="label">Disable</label>
                                    </li>
                                </ul>
                                </div>
                            </div>
                            </div>
                        </div> */}
              </div>
            </div>
          </div>

          <div class="boxrepetform">
            <h4 class="tabcont_Sublable">Listing Counts Settings</h4>
            <div class="Tbaleflex">
              <div class="Table">
                <div class="Table-row">
                  <div class="Table-row-item">
                    <div class="lefttabl_lable">
                      <span>Listing visit counter</span>
                    </div>
                  </div>
                  <div class="Table-row-item">
                    <div class="dropdownsid">
                      <div class="form_group">
                        <ul class="list">
                          <li class="list__item">
                            <input
                              type="radio"
                              id="listingVisitCounter1"
                              name="listingVisitCounter"
                              value="1"
                              onClick={handleListingVisitCounter}
                              checked={
                                listingVisitCounter == 1 ? "checked" : ""
                              }
                            />
                            <label for="listingVisitCounter1" class="label">
                              Enable
                            </label>
                          </li>
                          <li class="list__item">
                            <input
                              type="radio"
                              id="listingVisitCounter2"
                              name="listingVisitCounter"
                              value="0"
                              onClick={handleListingVisitCounter}
                              checked={
                                listingVisitCounter == 0 ? "checked" : ""
                              }
                            />
                            <label for="listingVisitCounter2" class="label">
                              Disable
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="Table-row">
                  <div class="Table-row-item">
                    <div class="lefttabl_lable">
                      <span>Picture view counter</span>
                    </div>
                  </div>
                  <div class="Table-row-item">
                    <div class="dropdownsid">
                      <div class="form_group">
                        <ul class="list">
                          <li class="list__item">
                            <input
                              type="radio"
                              id="pictureViewCounter1"
                              name="pictureViewCounter"
                              value="1"
                              onClick={handlePictureViewCounter}
                              checked={pictureViewCounter == 1 ? "checked" : ""}
                            />
                            <label for="pictureViewCounter1" class="label">
                              Enable
                            </label>
                          </li>
                          <li class="list__item">
                            <input
                              type="radio"
                              id="pictureViewCounter2"
                              name="pictureViewCounter"
                              value="0"
                              onClick={handlePictureViewCounter}
                              checked={pictureViewCounter == 0 ? "checked" : ""}
                            />
                            <label for="pictureViewCounter2" class="label">
                              Disable
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="Table-row">
                  <div class="Table-row-item">
                    <div class="lefttabl_lable">
                      <span>Posting date in listings</span>
                    </div>
                  </div>
                  <div class="Table-row-item">
                    <div class="dropdownsid">
                      <div class="form_group">
                        <ul class="list">
                          <li class="list__item">
                            <input
                              type="radio"
                              id="postingDateInListings1"
                              name="postingDateInListings"
                              value="1"
                              onClick={handlePostingDateInListings}
                              checked={
                                postingDateInListings == 1 ? "checked" : ""
                              }
                            />
                            <label for="postingDateInListings1" class="label">
                              Enable
                            </label>
                          </li>
                          <li class="list__item">
                            <input
                              type="radio"
                              id="postingDateInListings2"
                              name="postingDateInListings"
                              value="0"
                              onClick={handlePostingDateInListings}
                              checked={
                                postingDateInListings == 0 ? "checked" : ""
                              }
                            />
                            <label for="postingDateInListings2" class="label">
                              Disable
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottomsend_button text-center">
          <button type="submit" class="savesidebutt">
            SAVE
          </button>
          <ToastContainer />
        </div>
      </form>
    </div>
  );
};

export default Listings;
