import React, { useState, useEffect } from 'react';
import axios from "axios";
import forgetAPI from "../../constant/api";
import logo002 from "../../constant/img";
import { Link } from "react-router-dom";


const ForgetPassword = () => {

  const [email, setEmail] = useState();
  const [responseBackend, setResponseBackend] = useState();
  const [status, setStatus] = useState();


  useEffect(() => {
    document.getElementById("main_app1").classList.remove("main_board");
    document.getElementById("main_app2").classList.remove("main_board_inner");
  }, [])

  const handleChange = (event) => {
    event.preventDefault();
    setEmail(event.target.value);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    await axios.post(forgetAPI.FORGETPASSWORD_API, { params: { email } }).then((res, err) => {
      console.log("sdgahjgsdfgsdfgvd", res);
      if (res.data.status == "success") {
        setResponseBackend(res.data.message);
        setStatus(res.data.status);
        //setEmail("");
      }
      else {
        setResponseBackend(res.data.message);
        setStatus(res.data.status);
        //setEmail("");
      }
    })
  }





  return (
    <>
      <header class="scndHeader">

        <div class="loginHeader">
          <Link to="/">
          <img src = "https://dlevelns.stage02.obdemo.com/image/logo/headerImage_1711958521736.png"/>
            </Link>
        </div>

      </header>
      <section class="signin_Newpage bg-light-dark repeat-section-login-New">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="loginbx_main_New">
                <div class="log_sign_inner_New">
                  <div class="loginheadName">Forgot Password</div>
                  <div class="tab-content">
                    <div class="text-center">
                      {
                        status == "success" ?
                          <p class="text-success">{responseBackend}</p> :
                          <p class="text-danger">{responseBackend}</p>
                      }
                    </div>
                    <div class="tab-pane active" id="tabs-1" role="tabpanel">
                      <form action="get" onSubmit={handleSubmit}>
                        <div class="formgrop_main_New row">
                          <div class="col-sm-12 margin_top_new">
                            <label>Email</label>
                            <div class="form-group">
                              <input onChange={handleChange} value={email} type="text" class="form-control New_control" placeholder="Email Address" required />
                            </div>
                          </div>
                          <div class="col-sm-12 marginfour_side">
                            <div class="form-group text-center">
                              <button type="submit" class="btnx btn-secondaryx">Reset Password</button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="footerside margin_top text-center"><h5 class="footercont">Copyright © 2025 dboroll Inc. All Rights Reserved.</h5></footer>
    </>
  )
}

export default ForgetPassword
